import React from "react";
import SectionHeader from "../Shared/SectionHeader";
import { Row, Col, Container } from "../UI/Grid";
import { Section } from "../UI/Section";
import { StaticImage } from "gatsby-plugin-image";
import DefaultVector from "../Shared/DefaultVector";

const PartnersClients = () => {
  return (
    <Section py={{ _: 16, lg: 0 }}>
      <DefaultVector>
        <Container>
          <Row justifyContent="between">
            <Col col={{ lg: 5 }}>
              <SectionHeader
                subtitle="Our Partners"
                title="Customers Who Trust Us"
                description="We have established ourselves as a reliable subcontractor for our customers from around the world."
              />
            </Col>
            <Col col={{ lg: 7 }} pb={10}>
              <Row alignItems="center" rowGap={4}>
                <Col col={{ _: 6, sm: 4 }}>
                  <StaticImage
                    src="../../images/cobham.png"
                    alt="cobham"
                    layout="fullWidth"
                  />
                </Col>
                <Col col={{ _: 6, sm: 4 }}>
                  <StaticImage
                    src="../../images/transphorm.png"
                    alt="transphorm"
                    layout="fullWidth"
                  />
                </Col>
                <Col col={{ _: 6, sm: 4 }}>
                  <StaticImage
                    src="../../images/littelfuse.png"
                    alt="littelfuse"
                    layout="fullWidth"
                  />
                </Col>
                <Col col={{ _: 6, sm: 4 }}>
                  <StaticImage
                    src="../../images/microchip.png"
                    alt="microchip"
                    layout="fullWidth"
                  />
                </Col>
                <Col col={{ _: 6, sm: 4 }}>
                  <StaticImage
                    src="../../images/nexperia.png"
                    alt="nexperia"
                    layout="fullWidth"
                  />
                </Col>
                <Col col={{ _: 6, sm: 4 }}>
                  <StaticImage
                    src="../../images/macom.png"
                    alt="macom"
                    layout="fullWidth"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </DefaultVector>
    </Section>
  );
};

export default PartnersClients;

import React from "react";
import Icon from "../UI/Icon";

const ArrowRightIcon = () => {
  return (
    <Icon viewBox="0 0 25 25">
      <path d="M5.5 13.5H16.67L11.79 18.38C11.4 18.77 11.4 19.41 11.79 19.8C12.18 20.19 12.81 20.19 13.2 19.8L19.79 13.21C20.18 12.82 20.18 12.19 19.79 11.8L13.21 5.19997C12.82 4.80997 12.19 4.80997 11.8 5.19997C11.41 5.58997 11.41 6.21997 11.8 6.60997L16.67 11.5H5.5C4.95 11.5 4.5 11.95 4.5 12.5C4.5 13.05 4.95 13.5 5.5 13.5Z" />
    </Icon>
  );
};

export default ArrowRightIcon;

import React from 'react'
import { Section } from '../UI/Section'
import { Row, Col, Container } from '../UI/Grid'
import SectionHeader from '../Shared/SectionHeader'
import { StaticImage } from 'gatsby-plugin-image'
import { Card } from '../UI/Card'
import DefaultVector from '../Shared/DefaultVector'
import Button from '../UI/Button'
import { Link } from 'gatsby'

const WhoWeAre = () => {
  return (
    <Section defaultVector py={{ _: 16, lg: 0 }}>
      <DefaultVector>
        <Container>
          <Row alignItems="center">
            <Col col={{ lg: 5 }} mb={{ _: 6, lg: 0 }}>
              <SectionHeader
                subtitle="Who we are"
                title="The Leading Provider Of Turnkey Manufacturing Services"
                description="Fastech is a leading Philippine semiconductor manufacturing company that has grown over the years by focusing on its passion for manufacturing excellence."
              />
              <Button as={Link} to="/our-company" variant="brand" mt={6}>
                Learn More
              </Button>
            </Col>
            <Col col={{ lg: 6, xl: 5 }} order={{ lg: -1 }}>
              <Card width="auto" display="inline-block" cardhidden="true" borderRadius="4px">
                <StaticImage
                  src="../../images/fastech-buidling.jpg"
                  alt="fastech building"
                  height={465}
                  width={471}
                  layout="constrained"
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </DefaultVector>
    </Section>
  )
}

export default WhoWeAre

import React from 'react'
import { Row, Col } from '../UI/Grid'
import { StaticImage } from 'gatsby-plugin-image'
import Element from '../UI/Element'

const CertificationList = () => {
  return (
    <Row justifyContent="space-between" mt={10}>
      <Col col={{ _: 6, lg: 'auto' }} mb={{ _: 6, lg: 0 }}>
        <StaticImage
          src="../../images/certicate-3.png"
          layout="constrained"
          height={155}
          width={155}
          alt="certificate"
        />
        <Element fontWeight={500} mt={6}>
          Cert. No. 44 111 091682
        </Element>
      </Col>
      <Col col={{ _: 6, lg: 'auto' }} mb={{ _: 6, lg: 0 }}>
        <StaticImage
          src="../../images/certicate-2.png"
          layout="constrained"
          height={155}
          width={155}
          alt="certificate"
        />
        <Element fontWeight={500} mt={6}>
          Cert. No. 44 100 092555
        </Element>
      </Col>
      <Col col={{ _: 6, lg: 'auto' }}>
        <StaticImage
          src="../../images/certicate-1.png"
          layout="constrained"
          height={155}
          width={155}
          alt="certificate"
        />
        <Element fontWeight={500} mt={6}>
          Cert. No. 44 104 16 93 0036
        </Element>
      </Col>
    </Row>
  )
}

export default CertificationList

import React from "react";
import { Section } from "../UI/Section";
import ScaledImage from "../UI/ScaledImage";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "../UI/Grid";
import SectionHeader from "../Shared/SectionHeader";
import CertificationList from "../Shared/CertificationList";

const Certifications = ({ subtitle, title, description, children }) => {
  return (
    <Section>
      <ScaledImage bg={true}>
        <StaticImage
          src="../../images/banner-certification.jpg"
          alt="banner"
          layout="fullWidth"
        />
      </ScaledImage>
      <Container textAlign="center" color="white">
        <Row justifyContent="center">
          <Col col={{ lg: 9 }}>
            <SectionHeader
              subtitle="CERTIFICATions"
              title="We are dedicated to a quality way of life"
              description="The company recognizes that quality is a continuous process. That is why, we always hold ourselves to the highest standards of quality. As a result, our quality systems reflect our dedication to meeting those standards."
              inverse
            />

            <CertificationList />
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default Certifications;

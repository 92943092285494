import React from 'react'
import { Subtitle, Title, Description, SmallerTitle } from './SectionHeader.styles'

const SectionHeader = ({ subtitle, title, smaller_title, description, children, inverse }) => {
  return (
    <>
      <Subtitle size="overline" mb={1} color={inverse && 'white'}>
        {subtitle}
      </Subtitle>
      <Title size="h2" mb={3} color={inverse && 'white'}>
        {title}
      </Title>
      <SmallerTitle size="h4" mb={3} color={inverse && 'white'}>
        {smaller_title}
      </SmallerTitle>
      <Description>{description}</Description>
      {children}
    </>
  )
}

export default SectionHeader

/* Leadership and excellence in providing world class electronic manufacturing services */

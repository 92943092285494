import React from 'react'
import SectionHeader from '../Shared/SectionHeader'
import Element from '../UI/Element'
import { Row, Col, Container } from '../UI/Grid'
import { Section } from '../UI/Section'
import { Paragraph } from '../UI/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Button from '../UI/Button'

const WhyChooseUs = () => {
  return (
    <Section>
      <Container>
        <Row justifyContent="between" rowGap={10}>
          <Col col={{ lg: 5 }}>
            <SectionHeader
              subtitle="why choose us"
              title="Leadership And Excellence In Providing World Class Electronic Manufacturing Services"
              description="Our 40 years of experience and track record has given Fastech its leading-edge knowhow in niche power semiconductor components and RF/Microwave modules. And with the company’s Strategic Secure Supply Sourcing, or 4S, we have provided our clients a reliable and robust supply chain over the years."
            />
            <Button as={Link} to="/our-advantages" variant="brand" mt={6}>
              Learn More
            </Button>
          </Col>
          <Col col={{ lg: 7 }}>
            <Row rowGap={{ _: 6, lg: 10 }} justifyContent="center">
              <Col col={{ sm: 6, lg: 12 }}>
                <Row textAlign={{ _: 'center', lg: 'left' }}>
                  <Col col={{ lg: 'auto' }}>
                    <StaticImage
                      src="../../images/returns.png"
                      height={80}
                      width={80}
                      alt="returns"
                    />
                  </Col>
                  <Col col={{ lg: 'fill' }}>
                    <Element color="brand" fontWeight="bold">
                      Custom Solutions
                    </Element>
                    <Paragraph>
                      We offer custom-solution services. We work closely with our clients and use
                      our industry expertise to deliver the product that meets their specific needs.
                    </Paragraph>
                  </Col>
                </Row>
              </Col>
              <Col col={{ sm: 6, lg: 12 }}>
                <Row textAlign={{ _: 'center', lg: 'left' }}>
                  <Col col={{ lg: 'auto' }}>
                    <StaticImage
                      src="../../images/longterm.png"
                      height={80}
                      width={80}
                      alt="long term"
                    />
                  </Col>
                  <Col col={{ lg: 'fill' }}>
                    <Element color="brand" fontWeight="bold">
                      Long-term relationships
                    </Element>
                    <Paragraph>
                      We have been a reliable partner of our clients from their humble beginnings
                      and have helped them grow their business by supplying them with quality
                      products.
                    </Paragraph>
                  </Col>
                </Row>
              </Col>
              <Col col={{ sm: 6, lg: 12 }}>
                <Row textAlign={{ _: 'center', lg: 'left' }}>
                  <Col col={{ lg: 'auto' }}>
                    <StaticImage
                      src="../../images/sustainable.png"
                      height={80}
                      width={80}
                      alt="sustainable"
                    />
                  </Col>
                  <Col col={{ lg: 'fill' }}>
                    <Element color="brand" fontWeight="bold">
                      Sustainable Development
                    </Element>
                    <Paragraph>
                      We proudly meet global manufacturing standards and take pride in being a
                      socially and environmentally responsible company that implements manufacturing
                      lead-free products and is able to use “green” molding compound in its
                      packages.
                    </Paragraph>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default WhyChooseUs

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Description, Subtitle, Title } from "../Shared/SectionHeader.styles";
import Button from "../UI/Button";
import { Row, Col, Container } from "../UI/Grid";
import ScaledImage from "../UI/ScaledImage";
import { Section } from "../UI/Section";
import ScaledElement from "../UI/ScaledElement";
import { Link } from "gatsby";

const ContactUs = () => {
  return (
    <Section py={0} scaled color="white">
      <ScaledElement scaleHeight={400} scaleWidth={1440} fullWidth>
        <ScaledImage bg={true}>
          <StaticImage
            src="../../images/banner-contact-alt.jpg"
            alt="bg"
            layout="fullWidth"
          />
        </ScaledImage>
        <Container>
          <Row justifyContent="center" textAlign="center">
            <Col col={{ lg: 9 }}>
              <Subtitle size="overline" mb={1} color="white">
                Contact Us
              </Subtitle>
              <Title size="h2" mb={3} color="white">
                Find out what we can do for your business
              </Title>
            </Col>
          </Row>
          <Row justifyContent="center" textAlign="center">
            <Col col={{ lg: 6 }}>
              <Description>
                Send us a message here and let us talk about how we can be of
                service. We are here to help you with your manufacturing needs.
              </Description>
              <Button
                as={Link}
                variant="white"
                to="/contact-us"
                mt={{ _: 6, lg: 10 }}
              >
                Inquire Now
              </Button>
            </Col>
          </Row>
        </Container>
      </ScaledElement>
    </Section>
  );
};

export default ContactUs;

import * as React from 'react'
import Site from '../components/Layout/Site'
import Certifications from '../components/Home/Certifications'
import WhatWeOffer from '../components/Home/WhatWeOffer'
import WhyChooseUs from '../components/Home/WhyChooseUs'
import PartnersClients from '../components/Home/PartnersClients'
import PressReleases from '../components/Home/PressReleases'
import WhoWeAre from '../components/Home/WhoWeAre'
import ContactUs from '../components/Home/ContactUs'
import HomeBanner from '../components/Home/HomeBanner'
import Seo from '../components/Layout/Seo'
import CompanyProfile from '../components/Home/CompanyProfile'
// markup
const IndexPage = () => {
  return (
    <Site home="true">
      <Seo title="Home" />
      <HomeBanner />
      <WhoWeAre />
      <WhatWeOffer />
      <CompanyProfile />
      <WhyChooseUs />
      <Certifications />
      <PartnersClients />
      <PressReleases />
      <ContactUs />
    </Site>
  )
}

export default IndexPage

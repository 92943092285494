import React from "react";
import styled from "styled-components";
import Element from "../UI/Element";
import { handleResponsiveSize, Heading } from "../UI/Typography";
import { Row, Col } from "../UI/Grid";
import ArrowRightIcon from "../Icons/ArrowRightIcon";
import theme from "../../utils/theme";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ScaledImage from "../UI/ScaledImage";
import { Link } from "gatsby";
import dayjs from "dayjs";
import { space } from "styled-system";

const FeaturedArticle = ({ title, thumbnail, date, to }) => {
  return (
    <Featured to={to}>
      <ScaledImage bg="true">
        <GatsbyImage image={getImage(thumbnail)} alt="article" />
      </ScaledImage>
      <Overlay />
      <ArticleContent p={{ _: 4, lg: 6 }}>
        <Date>{dayjs(date).format("MMMM YYYY")}</Date>
        <Row alignItems="end">
          <Col col={{ lg: "fill" }}>
            <Heading>{title}</Heading>
          </Col>
          <Col col="auto">
            <ArticleIcon>
              <ArrowRightIcon />
            </ArticleIcon>
          </Col>
        </Row>
      </ArticleContent>
    </Featured>
  );
};

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  background: linear-gradient(
      0deg,
      rgba(26, 35, 126, 0.5),
      rgba(26, 35, 126, 0.5)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  transition: ${theme.transition};
`;

const Featured = styled(Link)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;

  &:before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 69.03%;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  &:hover {
    ${Overlay} {
      visibility: visible;
      opacity: 1;
    }
  }

  ${Heading} {
    color: white;
    line-height: 1.2;
  }
`;

export const Date = styled.div`
  text-transform: uppercase;
  ${handleResponsiveSize("overline")};
  ${space}
`;

const ArticleContent = styled(Element)`
  position: relative;
  z-index: 1;
  color: white;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); ;
`;

const ArticleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 34px;
  height: 34px;
  background: #304ffe;
  color: white;
  ${handleResponsiveSize("h6")};
`;

export default FeaturedArticle;

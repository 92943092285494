import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { Description, Title } from '../Shared/SectionHeader.styles'
import { Row, Col, Container } from '../UI/Grid'
import ScaledElement from '../UI/ScaledElement'
import ScaledImage from '../UI/ScaledImage'
import Element from '../UI/Element'
import theme from '../../utils/theme'

const HomeBanner = () => {
  return (
    <ScaledElement scaleHeight={700} scaleWidth={1440} fullWidth>
      <ScaledImage bg="true">
        <StaticImage src="../../images/banner-home.jpg" alt="banner" />
      </ScaledImage>
      <Element width="100%">
        <Container py={20} textAlign="center">
          <Row justifyContent="center">
            <Col col={{ md: 8 }}>
              <Title mb={4} size="display-3">
                Technology Is Our Passion
                <br />
                Quality Is Our Way Of Life
              </Title>
            </Col>
          </Row>
          <Row justifyContent="center">
            <Col col={{ md: 7 }}>
              <Description size="h5">
                We are committed to providing highly proficient technical labor for complex
                engineered products.
              </Description>
            </Col>
          </Row>
        </Container>

        <Mouse>
          <MouseScroll />
        </Mouse>
      </Element>
    </ScaledElement>
  )
}

const Mouse = styled.div`
  position: absolute;
  display: block;
  width: 25px;
  height: 40px;
  margin: 0 auto 20px;
  border: 2px solid ${theme.colors.brand};
  border-radius: 23px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

const MouseScroll = styled.span`
  position: absolute;
  display: block;
  top: 29%;
  left: 50%;
  width: 4px;
  transform: translateX(-50%);
  height: 4px;
  background: ${theme.colors.brand};
  border-radius: 50%;
  animation: ani-mouse 2.5s linear infinite;

  @keyframes ani-mouse {
    0% {
      opacity: 1;
      top: 19%;
    }
    15% {
      opacity: 1;
      top: 35%;
    }
    50% {
      opacity: 0;
      top: 35%;
    }
    100% {
      opacity: 0;
      top: 19%;
    }
  }
`

export default HomeBanner

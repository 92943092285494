import React from 'react'
import styled from 'styled-components'
import { Section } from '../UI/Section'
import { Row, Col, Container } from '../UI/Grid'
import Button from '../UI/Button'
import SectionHeader from '../Shared/SectionHeader'
import { Card, CardContent } from '../UI/Card'
import { space } from 'styled-system'
import { handleResponsiveSize, Paragraph } from '../UI/Typography'
import ScaledImage from '../UI/ScaledImage'
import { StaticImage } from 'gatsby-plugin-image'
import theme from '../../utils/theme'
import { Link } from 'gatsby'
import SectionVector from '../Shared/SectionVector'

const WhatWeOffer = () => {
  return (
    <Section bg="gray">
      <SectionVector
        height={101}
        width={140}
        d="M0 100.511H140V61.6222H40.4028V0.51123H0V100.511Z"
        left="0"
        bottom="0"
      />

      <Container>
        <Row rowGap={8}>
          <Col col={{ lg: 5 }} textAlign={{ _: 'center', lg: 'left' }}>
            <SectionHeader
              subtitle="What we Offer"
              title="Manufacturing Solutions Through Flexible & Cost-Competitive Services"
              description=""
            >
              <Button
                as={Link}
                to="/contact-us"
                variant="brand"
                mt={{ _: 0, lg: 7 }}
                state={{ message_type: 'products' }}
              >
                Inquire Now
              </Button>
            </SectionHeader>
          </Col>
          <Col col={{ lg: 7 }}>
            <Row>
              <Col col={{ lg: 12 }}>
                <Offer
                  as={Link}
                  mb={6}
                  rounded="true"
                  cardhidden="true"
                  to="/products/power-semi-conductors"
                >
                  <Row gutterSize={0}>
                    <Col col={{ sm: 5, md: 4 }}>
                      <ImageWrapper>
                        <ScaledImage bg="true">
                          <StaticImage
                            src="../../images/rf-and-modules.jpg"
                            alt="power semiconductor"
                            layout="fullWidth"
                          />
                        </ScaledImage>
                      </ImageWrapper>
                    </Col>
                    <Col col={{ sm: 7, md: 8 }}>
                      <CardContent>
                        <OfferTitle>Power Semiconductor</OfferTitle>
                        <Paragraph>
                          We assemble a wide range of power semiconductor components with leading
                          edge materials such as Silicon, Silicon Carbide, and Gallium Nitride for
                          various devices like MOSFETs, IGBTs, HEMT, Regulators, and more.
                        </Paragraph>
                      </CardContent>
                    </Col>
                  </Row>
                </Offer>
              </Col>
              <Col col={{ lg: 12 }}>
                <Offer
                  as={Link}
                  rounded="true"
                  cardhidden="true"
                  to="/products/rf-microwave-modules"
                >
                  <Row gutterSize={0}>
                    <Col col={{ sm: 5, md: 4 }}>
                      <ImageWrapper>
                        <ScaledImage bg="true">
                          <StaticImage
                            src="../../images/power-semiconductor.jpg"
                            alt="rf and modules"
                            layout="fullWidth"
                          />
                        </ScaledImage>
                      </ImageWrapper>
                    </Col>
                    <Col col={{ sm: 7, md: 8 }}>
                      <CardContent>
                        <OfferTitle>RF and Microwave Modules</OfferTitle>
                        <Paragraph>
                          We also assemble a wide range of RF and Microwave Modules such as RF
                          Components, Time Frequency Oscillators, and Amplifiers. In addition to
                          this, we are also capable of 5G Test & Measurements modules, and High end
                          Wide band Amplifiers for SATCOM products.
                        </Paragraph>
                      </CardContent>
                    </Col>
                  </Row>
                </Offer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

const Offer = styled(Card)`
  box-shadow: none;
  color: ${theme.colors.text};
  ${space};

  &:hover,
  &:focus {
    color: ${theme.colors.text};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
`

const OfferTitle = styled.div`
  color: ${theme.colors.brand};
  font-weight: ${theme.fontWeights.semibold};
  margin-bottom: 8px;
  ${handleResponsiveSize('h6')};

  svg {
    ${handleResponsiveSize('h4')};
  }
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  color: #fff;
  height: 100%;

  &:before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
`

export default WhatWeOffer

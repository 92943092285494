import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from '../UI/Button'
import Element from '../UI/Element'
import { Col, Container, Row } from '../UI/Grid'
import { Section } from '../UI/Section'
import { Heading, Paragraph } from '../UI/Typography'
import DownloadIcon from '../Icons/DownloadIcon'
import { graphql, useStaticQuery } from 'gatsby'
import DefaultVector from '../Shared/DefaultVector'

const CompanyProfile = () => {
  const supplierPdf = useStaticQuery(graphql`
    query powerPdfQuery {
      allFile(filter: { relativePath: { eq: "Supplier_Profile.pdf" } }) {
        nodes {
          publicURL
        }
      }
    }
  `)

  return (
    <Section bg="brand" color="white" py={{ _: 16, lg: 0 }}>
      <DefaultVector inverse>
        <Container py={{ lg: 16 }}>
          <Row rowGap={4}>
            <Col col={{ lg: 6 }}>
              <Row alignItems="center" mb={4}>
                <Col col={{ _: 'auto' }}>
                  <StaticImage
                    src="../../images/fastech-profile.png"
                    alt="fastech"
                    height={82}
                    width={86}
                    layout="constrained"
                  />
                </Col>
                <Col col={{ _: 'fill' }}>
                  <Heading size="h1" color="white">
                    Fastech Company Profile  
                  </Heading>
                </Col>
              </Row>
              <Paragraph>
                Fastech is one of the leading semiconductor companies based in the Philippines that
                has been recognized in the field of Power Semiconductors and RF Microwave Modules.
                The company has received many awards and certifications over the years due to its
                unwavering commitment to manufacturing excellence.
              </Paragraph>
            </Col>
            <Col col={{ lg: 6 }}>
              <Heading size="h1" color="white" mb={4}>
                ファステック企業概要
              </Heading>
              <Paragraph mb={6}>
                私たちファステックは、フィリピン半導体業界をリードするフィリピンローカル企業で、パワー半導体、高周波・マイクロ波モジュール分野で広く認知されています。
                <br />
                <br />
                製造技術に強み・こだわりを持っており、その姿勢は顧客からも高く評価され、何十年にもわたり幾多もの賞を受賞しています。
              </Paragraph>
              <Button
                variant="white"
                as="a"
                href={supplierPdf.allFile.nodes[0].publicURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadIcon />
                <Element ml={2}>Download</Element>
              </Button>
            </Col>
          </Row>
        </Container>
      </DefaultVector>
    </Section>
  )
}

export default CompanyProfile

import React, { useState, useEffect } from 'react'
import SectionHeader from '../Shared/SectionHeader'
import { Row, Col, Container } from '../UI/Grid'
import { Section } from '../UI/Section'
import Element from '../UI/Element'
import Button from '../UI/Button'
import { Link } from 'gatsby'
import FeaturedArticle from '../Cards/FeaturedArticle'
import axios from 'axios'

const PressReleases = () => {
  const isBrowser = typeof window !== 'undefined'
  const [processing, setProcessing] = useState(true)

  const [articles, setArticles] = useState([])

  useEffect(() => {
    axios
      .get(
        `${
          isBrowser && window?.location?.origin === 'https://fastech.digiteer.dev'
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/news`,
      )
      .then(res => {
        setArticles(res.data.filter(i => i.featured))
      })
      .finally(() => setProcessing(false))
  }, [])

  if (processing) {
    return false
  }

  return (
    articles?.length > 0 && (
      <Section bg="gray">
        <Container>
          <Element textAlign="center">
            <SectionHeader
              subtitle="press releases"
              title="The Latest News"
              description="Read up on what we are up to and be the first to learn what's new with Fastech."
            />
          </Element>
          {/* {articles.length > 0 && (
          <Row my={10} rowGap={4}>
            {articles.slice(0, 3).map(a => (
              <Col col={{ sm: 6, lg: 4 }} key={a.id}>
                <FeaturedArticle
                  to={`/press-releases/${a.slug}`}
                  date={a.published_date}
                  thumbnail={a.photo}
                  title={a.title}
                />
              </Col>
            ))}
          </Row>
        )} */}

          <Element textAlign="center" mt={6}>
            <Button as={Link} to="/press-releases" variant="brand">
              View All Articles
            </Button>
          </Element>
        </Container>
      </Section>
    )
  )
}

export default PressReleases
